import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Gap from 'components/common/Gap/Gap';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import Spinner from 'components/common/Spinner/Spinner';
import { Row } from 'react-flexbox-grid';
import styles from './AllTeamMembers.module.scss';
import { getTeam } from 'services/team';
import TeamMember from '../TeamMember/TeamMember';
import Text from 'components/common/Text/Text';
import { ROUTES } from 'types/routes';

const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          start {
            team {
              headingLarge
            }
          }
        }
      }
    }
  }
`;

export interface TeamMember {
  description: string;
  email: string;
  id: string;
  name: string;
  picture: { url: string };
  surname: string;
  position: string;
  isPartner?: boolean;
}

const Partners = ({ partners }: { partners: TeamMember[] }) => {
  const navigate = useCallback(id => {
    if (typeof window !== 'undefined') {
      window.location.href = `${ROUTES.TEAM}?id=${id}`;
    }
  }, []);

  return (
    <>
      {partners.map((member: TeamMember) => (
        <div
          key={member.id}
          className={cn(styles.member, styles.partner)}
          onClick={() => navigate(member.id)}
        >
          <div
            style={
              member &&
              member.picture && {
                backgroundImage: `url("${member.picture.url}")`,
              }
            }
            className={cn(styles.memberImg, styles.partnerImg)}
          />
          <div className={styles.memberTextWrapper}>
            <Text
              color="secondary"
              block
              fontSize="1.9rem"
              className={styles.name}
              align="center"
              weight="bold"
              transform="uppercase"
              alternativeFontFamily
            >
              {member.name} {member.surname}
            </Text>
            <Text
              color="light"
              block
              align="center"
              className={styles.position}
              transform="uppercase"
              fontSize=".85rem"
            >
              {member.position}
            </Text>
          </div>
        </div>
      ))}
    </>
  );
};

const OtherMembers = ({ members }: { members: TeamMember[] }) => {
  const navigate = useCallback(id => {
    if (typeof window !== 'undefined') {
      window.location.href = `${ROUTES.TEAM}?id=${id}`;
    }
  }, []);

  return (
    <>
      {members.map((member: TeamMember) => (
        <div
          key={member.id}
          className={styles.member}
          onClick={() => navigate(member.id)}
        >
          <div
            style={
              member &&
              member.picture && {
                backgroundImage: `url("${member.picture.url}")`,
              }
            }
            className={styles.memberImg}
          />
          <div className={styles.memberTextWrapper}>
            <Text
              color="secondary"
              block
              fontSize="1.5rem"
              className={styles.name}
              align="center"
              weight="bold"
              transform="uppercase"
              alternativeFontFamily
            >
              {member.name} {member.surname}
            </Text>
            <Text
              color="light"
              block
              align="center"
              className={styles.position}
              transform="uppercase"
              fontSize=".8rem"
            >
              {member.position}
            </Text>
          </div>
        </div>
      ))}
    </>
  );
};

const AllTeamMembers = () => {
  const data = useStaticQuery(query);
  const { headingLarge } = data.allDataJson.edges[0].node.start.team;
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState([]);

  const partners = team.filter((member: TeamMember) => member.isPartner);
  const otherTeamMembers = team.filter(
    (member: TeamMember) => !member.isPartner
  );

  useEffect(() => {
    getTeam().then(team => {
      setTeam(team);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Gap top="md">
        <SectionHeading largeHeading={headingLarge} color="primary" centered />
      </Gap>
      {isLoading ? (
        <Spinner />
      ) : (
        <Gap bottom="xl" top="md">
          {team.length ? (
            <>
              <Row center="xs">
                <Partners partners={partners} />
              </Row>
              <Row center="xs">
                <OtherMembers members={otherTeamMembers} />
              </Row>
            </>
          ) : (
            <Gap top="xxl">Brak członków zespołu</Gap>
          )}
        </Gap>
      )}
    </>
  );
};

export default AllTeamMembers;
