import React, { FC, useEffect, useState, useCallback } from 'react';
import Spinner from 'components/common/Spinner/Spinner';
import styles from './TeamMember.module.scss';
import Gap from 'components/common/Gap/Gap';
import Text from 'components/common/Text/Text';
import { Link } from 'gatsby';
import { Icon } from 'semantic-ui-react';
import { getTeam } from 'services/team';
import { TeamMember } from '../AllTeamMembers/AllTeamMembers';
import { Row } from 'react-flexbox-grid';
import { ROUTES } from 'types/routes';

interface TeamMemberProps {
  id?: string;
}

const navigateToAllMembers = () => {
  if (typeof window !== 'undefined') {
    window.location.href = ROUTES.TEAM;
  }
};

const TeamMemberPage: FC<TeamMemberProps> = ({ id }) => {
  const [member, setMember] = useState<TeamMember>();
  const [nextMemberId, setNextMemberId] = useState('');

  useEffect(() => {
    getTeam().then((team: TeamMember[]) => {
      const teamSorted = [
        ...team.filter(({ isPartner }) => isPartner),
        ...team.filter(({ isPartner }) => !isPartner),
      ];
      const currentMember = teamSorted.find(
        (member: TeamMember) => member.id === id
      );
      const currentMemberIndex = teamSorted.findIndex(
        (member: TeamMember) => member.id === id
      );
      const nextMemberIndex =
        currentMemberIndex === teamSorted.length - 1
          ? 0
          : currentMemberIndex + 1;

      setNextMemberId(teamSorted[nextMemberIndex].id);
      setMember(currentMember);
    });
  }, []);

  const navigateToNextMember = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.location.href = `${ROUTES.TEAM}?id=${nextMemberId}`;
    }
  }, [nextMemberId]);

  if (!member) return <Spinner />;

  return (
    <div className={styles.wrapper}>
      <div
        style={
          member &&
          member.picture && {
            backgroundImage: `url("${member.picture.url}")`,
          }
        }
        className={styles.img}
      />
      <div className={styles.overlay}>
        <Gap bottom="lg">
          <Row between="xs" middle="xs">
            <div>
              <Text
                color="light"
                block
                fontSize="5.5rem"
                transform="uppercase"
                weight="bold"
                className={styles.surname}
              >
                {member.surname}
              </Text>
              <Text color="light" block fontSize="4rem" className={styles.name}>
                {member.name}
              </Text>
            </div>
            <Icon
              name="chevron right"
              size="huge"
              className={styles.nextIcon}
              onClick={navigateToNextMember}
            />
          </Row>
        </Gap>
        <Gap bottom="lg">
          <Text color="neutral" fontSize="1.2rem">
            Email: {member.email}
          </Text>
        </Gap>
        <Text
          color="light"
          className={styles.description}
          alternativeFontFamily
        >
          {member.description}
        </Text>
      </div>
      <Gap top="xl">
        <Link to={ROUTES.TEAM}>
          <Text color="neutral" fontSize="1.3rem" className={styles.goBackLink}>
            <Icon name="chevron left" onClick={navigateToAllMembers} />
            <span> Zobacz wszystkich członków zespołu</span>
          </Text>
        </Link>
      </Gap>
    </div>
  );
};

export default TeamMemberPage;
