import React, { useMemo } from 'react';
import AllTeamMembers from './AllTeamMembers/AllTeamMembers';
import TeamMember from './TeamMember/TeamMember';
import Layout from 'components/common/Layout/Layout';
import Header from 'components/common/Header/Header';
import styles from './Team.module.scss';

const Team = () => {
  if (typeof window === 'undefined') return null;

  const urlParams = new URLSearchParams(window.location.search);
  const newsId = useMemo(() => urlParams.get('id'), [urlParams]);

  return (
    <Layout>
      <Header withBackground block />
      <main className={styles.main}>
        {newsId ? <TeamMember id={newsId} /> : <AllTeamMembers />}
      </main>
    </Layout>
  );
};

export default Team;
