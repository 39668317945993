import axios from 'axios';
import config from '../config';

export interface GetNewsRequest {
  _limit: number;
  _start: number;
}

export const getTeam = (params?: GetNewsRequest) =>
  axios
    .get(`${config.apiUrl}/teammembers`, {
      params: { _sort: 'sortingNumber', ...params },
    })
    .then(({ data }) => data);

export const getTeamMemberById = (id: string) =>
  axios.get(`${config.apiUrl}/teammembers/${id}`).then(({ data }) => data);
